import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../../../common/Footer";
import AvailableOnMobile from "../../../common/available-on-mobile";

import CurrentAccountOverview from "../../../assets/img/current-account-overview.png";
import CurrentAccountOverviewMobile from "../../../assets/img/current-account-overview-mobile.png";
import CurrentAccountCard from "../../../assets/img/card-current-account.png";
import MapImg from "../../../assets/img/india-map.png";
import solar_star from "../../../assets/img/solar_star.svg";
import sendPayment from "../../../assets/img/send-payment.png";
import sendPaymentMobile from "../../../assets/img/send-payment-mobile.png";
import schedulePayments from "../../../assets/img/schedule-payments.png";
import schedulePaymentsMobile from "../../../assets/img/schedule-payments-mobile.png";
import manageTeam from "../../../assets/img/manage-team.png";
import manageTeamMobile from "../../../assets/img/manage-team-mobile.png";

import CurrentAccountOverviewLazy from "../../../assets/img/webp/current-account-overview.webp";
import CurrentAccountOverviewMobileLazy from "../../../assets/img/webp/current-account-overview-mobile.webp";
import CurrentAccountCardLazy from "../../../assets/img/webp/card-current-account.webp";
import MapImgLazy from "../../../assets/img/webp/india-map.webp";
import sendPaymentLazy from "../../../assets/img/webp/send-payment.webp";
import sendPaymentMobileLazy from "../../../assets/img/webp/send-payment-mobile.webp";
import schedulePaymentsLazy from "../../../assets/img/webp/schedule-payments.webp";
import schedulePaymentsMobileLazy from "../../../assets/img/webp/schedule-payments-mobile.webp";
import manageTeamLazy from "../../../assets/img/webp/manage-team.webp";
import manageTeamMobileLazy from "../../../assets/img/webp/manage-team-mobile.webp";

import { Helmet } from "react-helmet";

const CurrentAccount = () => {
  useEffect(() => {
    document.title = "Tijoree";
    let descMeta = document.querySelector('meta[name="description"]');
    descMeta.setAttribute(
      "content",
      "Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking."
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    AOS.init({
      easing: "ease-out-back",
      duration: 700,
      once: true,
      // mirror: true,
      disable: "mobile",
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Tijoree</title>
        <meta
          name="description"
          content=" Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking."
        />
      </Helmet>
      <div className="page-wrapper">
        <section
          id="inner-top-section"
          className="inner-top-section grey-bg-2 current-account"
        >
          <div className="right-card">
            <picture>
              <source
                type="image/webp"
                srcSet={CurrentAccountCardLazy}
              ></source>
              <source type="image/png" srcSet={CurrentAccountCard}></source>
              <img
                className="desktop"
                alt="Current Account Card"
                src={CurrentAccountCard}
              />
            </picture>
          </div>
          <div className="container-md">
            <div className="inner-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title">Current Account</div>
                  <div className="row">
                    <div className="col-md-5">
                      <h2 className="size40 mw85">
                        All Business Finances in One Place
                      </h2>
                      <div className="action-btns d-lg-block d-md-none d-sm-none">
                        <Link to={"/form"} className="button btn-yellow">
                          Open a Current Account
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-4">
                      <p className="size20">
                        Access everything you need seamlessly - transactions,
                        payments, GST, TDS and more
                      </p>
                      <div className="action-btns d-lg-none d-md-none d-sm-block">
                        <Link to={"/form"} className="button btn-yellow">
                          Open a Current Account
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-img text-center">
              {/*
              <ProgressiveImage delay={2000} src={CurrentAccountOverview} placeholder={CurrentAccountOverviewLazy}>
                {(src, loading) => (
                  <img className="desktop" style={{ opacity: loading ? 0.5 : 1, }} src={src} alt="Current Account Overview" />
                )}
              </ProgressiveImage>
              */}
              <picture>
                <source
                  type="image/webp"
                  srcSet={CurrentAccountOverviewLazy}
                ></source>
                <source
                  type="image/png"
                  srcSet={CurrentAccountOverview}
                ></source>
                <img
                  className="desktop"
                  alt="Current Account Overview"
                  src={CurrentAccountOverview}
                />
              </picture>

              <picture>
                <source
                  type="image/webp"
                  srcSet={CurrentAccountOverviewMobileLazy}
                ></source>
                <source
                  type="image/png"
                  srcSet={CurrentAccountOverviewMobile}
                ></source>
                <img
                  className="mobile"
                  alt="Current Account Overview"
                  src={CurrentAccountOverviewMobile}
                />
              </picture>
            </div>
          </div>
        </section>

        <section id="current-ac-figures" className="current-ac-figures">
          <div className="container-md">
            <div className="content-box">
              <div className="row justify-content-center">
                <div className="col-lg-5 text-center">
                  <h4 className="size28">Earn upto 7% Interest*</h4>
                  {/* <p className='size20'>Lorem ipsum dolor sit amet consectetur. Eu luctus a pharetra odio quis.</p> */}
                </div>
                <div className="col-lg-1 text-center"></div>
                <div className="col-lg-5 text-center">
                  <h4 className="size28">₹0 Non Maintenance Charges*</h4>
                  {/* <p className='size20'>Lorem ipsum dolor sit amet consectetur. Eu luctus a pharetra odio quis.</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="switch-account" className="switch-account">
          <div className="container-md">
            <div className="main-box">
              <div className="row">
                <div className="col-md-12  mobile-order-2">
                  <div className="row inner-content">
                    <div className="col-md-1">
                      <p>
                        <img
                          src={solar_star}
                          alt="Best Current Account in India"
                        />
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h2 className="size40">
                        Switch to the best Current Account in India
                      </h2>
                    </div>
                    <div className="col-md-5 text-end">
                      <div className="action">
                        <Link className="button btn-yellow" to={"/form"}>
                          Switch to Tijoree Current Account
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="money-transfer" className="money-transfer gradient-box">
          <div className="container-md">
            <div className="content-box">
              <div className="row hover-box">
                <div className="col-md-6 mobile-order-2">
                  <div className="money-transfer-content">
                    <h1 className="size64 text-white">
                      Send & Receive Money from Anywhere
                    </h1>
                    {/*
                    <p className='size20'>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                    <p className='d-sm-block d-md-none d-lg-none'><Link className="button btn-white" to={'/'}>Get Started</Link></p>
                     */}
                  </div>
                </div>
                <div className="col-md-6 mobile-order-1">
                  <div className="mapImg">
                    <picture>
                      <source type="image/webp" srcSet={MapImgLazy}></source>
                      <source type="image/png" srcSet={MapImg}></source>
                      <img
                        className="hover-img"
                        alt="Send & Receive Money from Anywhere"
                        src={MapImg}
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="payment-schedule-new" className="payment-schedule-new">
          <div className="container-md">
            <div className="row align-items-center hover-out">
              <div className="col-md-6">
                <div className=" payment-image">
                  <div className="image-wrap">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={sendPaymentLazy}
                      ></source>
                      <source type="image/png" srcSet={sendPayment}></source>
                      <img
                        className="desktop hover-img"
                        alt="Send Payments in Bulk"
                        src={sendPayment}
                      />
                    </picture>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={sendPaymentMobileLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={sendPaymentMobile}
                      ></source>
                      <img
                        className="mobile hover-img"
                        alt="Send Payments in Bulk"
                        src={sendPaymentMobile}
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="payment-text">
                  <h3 className="size40">
                    Make multiple payments at once saving time and effort.
                  </h3>
                </div>
              </div>
            </div>
            <div className="row align-items-center hover-out">
              <div className="col-md-6">
                <div className=" payment-image">
                  <div className="image-wrap">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={schedulePaymentsLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={schedulePayments}
                      ></source>
                      <img
                        className="desktop hover-img"
                        alt="Schedule your Payments"
                        src={schedulePayments}
                      />
                    </picture>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={schedulePaymentsMobileLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={schedulePaymentsMobile}
                      ></source>
                      <img
                        className="mobile hover-img"
                        alt="Schedule your Payments"
                        src={schedulePaymentsMobile}
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="payment-text">
                  <h3 className="size40">
                    Schedule payments in advance & maintain your financial
                    commitments effortlessly.
                  </h3>
                </div>
              </div>
            </div>
            <div className="row align-items-center hover-out">
              <div className="col-md-7">
                <div className=" payment-image">
                  <div className="image-wrap">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={manageTeamLazy}
                      ></source>
                      <source type="image/png" srcSet={manageTeam}></source>
                      <img
                        className="desktop hover-img"
                        alt="Manage your team"
                        src={manageTeam}
                      />
                    </picture>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={manageTeamMobileLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={manageTeamMobile}
                      ></source>
                      <img
                        className="mobile hover-img"
                        alt="Manage your team"
                        src={manageTeamMobile}
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="payment-text">
                  <h3 className="size40">
                    Assign roles, control permissions efficiently
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*
        <section id="payment-schedule" className="payment-schedule">
          <div className="container-md">
            <div className="row">
              <div className="col-md-6">
                <div className="paySchedule-card hover-box">
                  <div className="inner-content">
                    <h1 className="hover-img">₹4,317</h1>
                    <img className="desktop hover-img" src={PaymentScheduledImg} alt="Payment Scheduled" />
                    <img className="mobile hover-img" src={PaymentScheduledImgMobile} alt="Payment Scheduled" />
                  </div>
                </div>
                <div className="card-inner-block">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <h6>Schedule your Payments</h6>
                    </div>
                    <div className="col-md-8">
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="transaction-card hover-box">
                  <img className="desktop hover-img" src={BulkPayment} alt="Bulk Payment" />
                  <img className="mobile hover-img" src={BulkPaymentMobile} alt="Bulk Payment" />
                </div>
                <div className="card-inner-block">
                  <div className="row">
                    <div className="col-md-4">
                      <h6>Send Money in Bulk Payments</h6>
                    </div>
                    <div className="col-md-8">
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}

        {/*
        <section id="approve-payment" className="approve-payment">
          <div className="container-md">
            <div className="row">
              <div className="col-md-6">
                <div className="payment-approval hover-box">
                  <div className="img-box">
                    <img className="desktop hover-img" src={PaymentApprovals} alt="Payment Approvals" />
                    <img className="mobile hover-img" src={PaymentApprovalsMobile} alt="Payment Approvals" />
                  </div>
                </div>
                <div className="card-inner-block">
                  <div className="row">
                    <div className="col-md-4">
                      <h6>Approve Payment Before its Made</h6>
                    </div>
                    <div className="col-md-8">
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="manage-team-access hover-box">
                  <img className="desktop hover-img" src={ManageTeamAccess} alt="Manage Team Access" />
                  <img className="mobile hover-img" src={ManageTeamAccessMobile} alt="Manage Team Access" />
                </div>
                <div className="card-inner-block">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <h6>Manage Team Access</h6>
                    </div>
                    <div className="col-md-8">
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}

        {/*
        <section id="collection-reminder" className="collection-reminder">
          <div className="container-md">
            <div class="main-head">
              <div class="head-icon reminder-icon"></div>
              <h2>Collection Reminder</h2>
              <p class="head-sub">Get paid by your customers</p>
            </div>
            <div className="purple-gradient-box hover-box">
              <div className="content-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="left-content">
                      <h4>Collect payments from your customers</h4>
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.
                        Morbi fermentum enim ut ultrices sapien diam feugiat.</p>
                      <div className="btn-groups">
                        <Link to={'/form'} className="button btn-white medium">Try Now</Link>
                        <Link to={'/'} className="button btn-purple medium">Know More</Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <img className="desktop hover-img" src={PaymentCollectionReminder} alt="Payment Collection Reminder" />
                    <img className="mobile hover-img" src={PaymentCollectionReminderMobile} alt="Payment Collection Reminder" />
                  </div>
                </div>
              </div>
            </div>          

            <div className="reminder-payments">
              <div className="row">
                <div className="col-md-6">
                  <div className="reminder hover-box">
                    <img className="desktop hover-img" src={ReminderTable} alt="Reminder" />
                    <img className="mobile hover-img" src={ReminderTableMobile} alt="Reminder" />
                  </div>
                  <div className="card-inner-block">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <h6>Schedule your Reminders</h6>
                      </div>
                      <div className="col-md-8">
                        <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="payment hover-box">
                    <img className="desktop hover-img" src={PaymentLink} alt="Payment Link" />
                    <img className="mobile hover-img" src={PaymentLinkMobile} alt="Payment Link" />
                  </div>
                  <div className="card-inner-block">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <h6>Payment Link</h6>
                      </div>
                      <div className="col-md-8">
                        <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
         */}

        {/*
        <section id="receive-payment" className="receive-payment">
          <div className="container-md">
            <div class="main-head text-end">
              <div class="head-icon store-icon"></div>
              <h2>Receive In-Store Payments</h2>
              <p class="head-sub">Collect Payment In-Store Seamlessly</p>
            </div>
            <div className="card-payment hover-box">
              <div className="row">
                <div className="col-md-4">
                  <img className="desktop hover-img" src={PaymentMachine} alt="Payment" />
                  <img className="mobile hover-img" src={PaymentMachineMobile} alt="Payment" />
                </div>
                <div className="col-md-8">
                  <div className="inner-content">
                    <div className="text-content">
                      <h4>Collect In-store UPI & Card Payments</h4>
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.
                        Morbi fermentum enim ut ultrices sapien diam feugiat.</p>
                    </div>
                    <div className="btn-box text-end">
                      <Link to={'/'} className="button btn-purple medium">Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}
        {/*
        <section id="track-receive-payment" className="track-receive-payment hover-box">
          <div className="container-md">
            <div className="content-box">
              <div className="row">
                <div className="col-md-3">
                  <h4>Track Received Payments</h4>
                </div>
                <div className="col-md-6">
                  <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                </div>
              </div>
              <div className="group-img">
                <div className="row">
                  <div className="col-md-3">
                    <Link className="button btn-purple-light medium" to={'/'}>Enquire Now</Link>
                  </div>
                  <div className="col-md-3">
                    <div className="middle-img">
                      <img className="hover-img" src={PayMethod} alt="Pay UPI" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="hover-img" src={TransactionsUpi} alt="Transactions UPI" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}

        {/*
        <section id="faq-section" className="faq-section">
          <div className="container-md">
            <div className="faq-head">
              <h1>FAQ</h1>
              <p>Frequently Asked Questions</p>
            </div>
            <div class="accordion" id="accountOpening">
              <div class="accordion-item">
                <h4 class="accordion-header" id="title1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapseOne" data-bs-parent="#accountOpening">What is the account opening process?</button></h4>
                <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="title1" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>Account opening of current account will be as per the existing process. Existing Account Opening Form (AOF) needs to be used for account opening. KYC &amp; related documents as applicable basis entity type.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapseTwo" data-bs-parent="#accountOpening">What is a One Person Company Account?</button></h4>
                <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="title2" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>The concept of OPC allows a single person to run a company limited by shares, and Sole proprietorship means an entity where it is run and owned by one individual and where there is no distinction between the owner and the business</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title3"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseThree" data-bs-parent="#accountOpening">Is AADHAAR Mandatory for Authorized Signatories?</button></h4>
                <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="title3" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>AADHAAR is Mandatory for all Authorized Signatories</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title4"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree" data-bs-parent="#accountOpening">I do not have a physical Pan Card, but I have a pan card number.?</button></h4>
                <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="title4" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>If a PAN card is not available, a printout of the PAN for the account holder may be taken from <a href="http://incometaxindia.gov.in/" target="_blank">http://incometaxindia.gov.in/</a> - the printout must be stamped “Downloaded/Verified at Branch” by Bank officials.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        {/*
        <section id="trusted-partners" className="trusted-partners purple-gradient-box">
          <div className="container-md">
            <div className="inner-box">
              <div className="row justify-content-lg-center">
                <div className="col-lg-6 col-md-13 col-sm-12 text-center">
                  <h2 className='size40'>Our Trusted Partners</h2>
                  <p>Powering your financial journey with the backing of esteemed partners: YES Bank, Unity Bank, and Visa.
                    Your business is in trusted hands every step of the way</p>
                  <div className="partners">
                    <img
                      src={partners}
                      alt="partners"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}

        <section id="cta-bg-curve" class="cta-bg-curve inner text-center">
          <div className="container-md">
            <h4>Join Tijoree & Grow your Business</h4>
            <Link className="button btn-purple" to={"/form"}>
              Enquire Now
            </Link>
          </div>
        </section>

        <section id="available-versions" className="available-versions">
          <div className="container-md">
            <AvailableOnMobile></AvailableOnMobile>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default CurrentAccount;
