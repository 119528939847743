import React, { useEffect } from "react";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  useEffect(() => {
    document.title = "Tijoree";
    // let descMeta=document.querySelector('meta[name="description"]')
    // descMeta.setAttribute("content", 'A B2B payments platform to optimize all of your business spends that combines the power of commercial credit cards and customisable tech to help you optimise your treasury returns and working capital.');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="page-wrapper">
      <section className="text-content-page terms-conditions">
        <div className="container-md">
          <h1>Terms & Conditions</h1>
          <p>
            The objectives of this Terms and Conditions (“
            <b>Terms and Conditions</b>”) are to make You aware of the rules
            that govern Your use of and access to the Tijoree website (defined
            below), as well as what to look forward from Tijoree Invest Private
            Limited (&quot;also referred to as &quot;<b>Tijoree</b>&quot;,
            &quot;<b>Company</b>&quot;, &quot;<b>We</b>&quot;, &quot;<b>Us</b>
            &quot;, and &quot;<b>Our</b>&quot; depending on the context &quot;)
            when you use and interact with the Tijoree website.
          </p>
          <p>
            According to the Information Technology Act of 2000 and the laws
            thereunder, as applicable and as occasionally amended, these Terms
            and Conditions are an electronic record. There are no physical or
            digital signatures necessary for this electronic record as the same
            is produced by a computer system. These Terms and Conditions are
            published in accordance with the requirements of Rule 3(1)(a) of the
            Information Technology (Intermediary Guidelines and Digital Media
            Ethics Code) Rules, 2021 created under the Information Technology
            Act, 2000 (as amended from time to time), and they serve as a
            legally binding and enforceable contract between Tijoree and the
            User (as defined below).
          </p>
          <p class="s2">
            <span>
              These Terms and Conditions apply to accessing and using the{" "}
            </span>
            Tijoree website{" "}
            <a
              href="https://www.tijoree.money/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tijoree website
            </a>{" "}
            https://www.tijoree.money/ (&quot;<b>Website</b>&quot;), mobile
            site, web/desktop plugins, and mobile application housed at
            https://www.tijoree.money/(&quot;<b>App</b>&quot;)
            <span>
              , which includes any and all related mini-links and the Services
              provided by Tijoree.{" "}
            </span>
            For the purposes of this Terms and Conditions, all the terms and
            conditions applicable to the Website shall also be applicable to the
            App and vice-versa.
          </p>

          <p>
            With the help of the Tijoree Website, companies may use technology
            to manage their payments and collections, banking, accounting, and
            compliance through a single dashboard.
          </p>
          <p>
            Tijoree is a technological platform and it is neither a bank nor
            does it claim to be licensed as one. The banks and financial
            institutions with RBI licenses offer the business current accounts,
            corporate cards, and other financial services/automation
            services/software services offered on the Tijoree Website. According
            to the limits outlined in the deposit insurance programme of the
            RBI, all monies in Tijoree powered Business Current Account are
            insured. The banking and financial services provided on the Tijoree
            Website are powered by our partner banks and financial institutions,
            and they adhere to all security standards and legal obligations
            established by those institutions, in compliance with current RBI
            rules.
          </p>

          <p>
            The User&#39;s use of and/or access to the Website is subject to
            these Terms and Conditions. These Terms and Conditions are an
            essential component of and must be read in conjunction with the
            following:
          </p>

          <ul id="l1">
            <li data-list-text="●">
              <p>
                The Privacy policy governing usage of the Website or any other
                form of communication with the User is available at [
                <i>insert link of Privacy Policy</i>].{" "}
                <span>
                  Your acceptance of this Terms and Conditions shall be
                  considered as Your deemed acceptance of the terms of the
                  Privacy Policy set out on the Website and vice versa.
                </span>
              </p>
            </li>
            <li data-list-text="●">
              <p>
                The Terms and Conditions set out by server providers that apply
                to the Service(s) used by You.
              </p>
            </li>
          </ul>
          <h4>Definitions</h4>

          <p>
            Unless the context otherwise requires, the following capitalized
            words shall have the meaning assigned to them below.
          </p>
          <p>
            “<b>Bank</b>” refers to any bank or financial institution that has a
            license from and is governed by the RBI, with whom Tijoree has
            collaborated to provide the Services. It also refers to any sponsor
            banks with which Tijoree has collaborated to provide software
            protocols and direct API connections.
          </p>
          <p>
            “<b>Business Current Account</b>” or “<b>Payment Account</b>” refers
            to a bank account that the User has connected to Tijoree Website for
            proceeds settling. The Business Current Account can be opened with
            any Bank and linked to the Tijoree Website for settlements, or it
            can be an existing account and the same can be linked to Tijoree
            Website by completing KYC verification. Any User&#39;s use of the
            Business Current Account is subject to the conditions outlined by
            the relevant bank.
          </p>
          <p>
            “<b>Customer</b>” shall refer to any person or organisation that
            engages in transactions with the User that result in the transfer of
            monies to the User.
          </p>
          <p>
            “<b>Know Your Customer</b>” or “<b>KYC</b>” shall mean all
            applicable laws, statutes, orders, and rules, including the RBI
            Guidelines issued from time to time, in accordance with which
            Tijoree obtains personal identifiable information from Users wishing
            to access or use the Tijoree Website.
          </p>
          <p>
            “<b>Online Business Account</b>”, or “<b>User Account</b>” refers to
            the online account that the User opened in compliance with the RBI
            Guidelines and subject to the Tijoree Website’s onboarding
            procedures.
          </p>
          <p>
            “<b>Payee</b>” means any individual using the Services to whom a
            payment is made (this includes users who use the Services to receive
            payments).
          </p>
          <p>
            “<b>Payer</b>” means any person who makes a payment on using the
            Services (and the term includes a User who uses the Services to make
            payment.
          </p>
          <p>
            “<b>RBI Guidelines</b>” refers to the extant rules, regulations,
            orders, directions, notifications and guidelines issued by RBI,
            including but not limited to - the Payment &amp; Settlement Systems
            Act, 2007, RBI Directions for opening and operation of accounts and
            settlement of payments for electronic payment transactions involving
            intermediaries dated November 24, 2009, the Guidelines on Regulation
            of Payment Aggregators and Payment Gateways dated March 17, 2020 and
            the Master Directions for Know Your Customer (KYC), 2016, as amended
            from time to time.
          </p>
          <p>
            “<b>Service(s)</b>” includes the services provided by Tijoree to the
            User at the request of the User, as listed below:
          </p>
          <p>
            Online Business Account for payments and collections; Corporate
            Cards;
          </p>
          <p>
            Includes any other products/services offered by Us, from time to
            time.
          </p>
          <p>
            “<b>Service Provider</b>” refers to any organisation, group of
            people, facility provider, lender, card issuing institution, bank,
            card processor, or clearing house network that uses its facilities
            or services to provide the Services.
          </p>
          <p>
            “<b>Transaction</b>” means a successful transfer of funds (a) from a
            User to a Payee; or (b) from a Payer to a User, as the context
            requires.
          </p>
          <p>
            “<b>User</b>”, “<b>You</b>”, “<b>Your</b>” and “<b>Yourself</b>”,
            any natural person or legal person (sole proprietor, partnership
            business, company, LLPs, trusts, societies, or HUFs), as the context
            dictates, that registers on the Tijoree Website and/or engages in
            transactions or uses Services. The word &quot;User&quot; also refers
            to any of the User&#39;s employees who access the Tijoree Website
            using their User Account credentials and have full access or
            restricted access, as applicable.
          </p>
          <h4>Eligibility for usage of the Website</h4>

          <p>
            Only those who qualify as &quot;competent to contract&quot; under
            Section 11 of the Indian Contract Act, 1872, and who can enter into
            contracts with legal effect under that statute may access or utilise
            the Tijoree Website.
          </p>
          <h4>Registration requirements and Accessibility</h4>

          <p>
            You must have a User Account registered on the Tijoree Website in
            order to access and utilise the Website and Services. You are given
            a User Account in collaboration with the Banks in compliance with
            the RBI Guidelines. In this regard, the User shall make all
            documents and information necessary to carry out KYC in accordance
            with the RBI Guidelines available to Tijoree and the Service
            Providers (where applicable). If you do not submit true, accurate,
            current, and complete information, Tijoree has the right to refuse
            your registration and revoke your access to the Website and/or
            Services. Tijoree has the right to request the self-attested copies
            of the papers that were submitted during or after the registration
            procedure in order to undertake KYC on the User.
          </p>
          <p>
            You understand and unconditionally agree that even if You are
            permitted to conduct Transaction on the Tijoree Website, the monies
            will not be credited to Your account if the User have outstanding
            KYC requirements according to RBI Guidelines. Additionally, Tijoree
            reserves the right to withhold the settlement amounts from the User
            upon non-fulfillment of Your KYC obligations, as stated above, to
            the satisfaction of Tijoree, and to ultimately reverse the funds to
            the account from which such payment originated if the non-fulfilment
            of KYC obligations persists.
          </p>
          <p>
            Based on the information you supply while registering on the Tijoree
            Webiste, a User Account will be made for you. It is pertinent to
            note that You will be fully responsible for anything that occurs
            through the User Account, hence, it is important that you keep the
            User credentials private. You are responsible for making sure that
            the User Account information is always accurate and up to date. You
            must notify Tijoree in writing at least 30 (thirty) working days in
            advance of any change to the User&#39;s email address, mobile
            number, address, authorization, control, or legal status, as well as
            any termination of the User&#39;s business.
          </p>
          <p>
            The security and secrecy of the login, password, and any other
            information relevant to the User Account, as well as preventing
            unauthorised access to the User Account and devices, are your
            constant responsibility. Tijoree gives a disclaimer of all
            responsibility for any error or abuse of the User Account made by
            the User, by anyone to whom the User has granted authorization, or
            by anyone who uses the User Account to access the Services.
          </p>
          <p>
            It shall be Your obligation to routinely check these Terms and
            Conditions for any amendments. Any revisions to these Terms and
            Conditions will be deemed accepted if You continue to use the
            Website. Tijoree grants the User a personal, non-exclusive,
            non-transferable, restricted right to access and use the Website,
            subject to the User&#39;s diligent compliance with these Terms and
            Conditions.
          </p>
          <p>
            You agree to use the Website only for legal business purposes and to
            refrain from engaging in any prohibited, illegal, or immoral
            behaviour or helping to in any way the continuation of any such
            activity that violates any law or regulation, including but not
            limited to the RBI Guidelines.
          </p>
          <p>
            This statement makes it clear that any money in the Business Current
            Account is kept by the appropriate Bank and is subject to the rules
            established by that Bank. The accountholder listed on the Bank&#39;s
            records shall be the rightful owner of any funds in the Business
            Current Account, subject to any relevant Bank fees. You shall be
            solely responsible for any liability arising from any Transaction
            carried out by You using the Business Current Account or any
            software or any other product/Service provided by Tijoree and
            Tijoree shall not be responsible for any liability arising
            therefrom.
          </p>
          <p>
            You will be accountable for any liabilities, expenses, or losses
            resulting from the User supplying false, inaccurate, or misleading
            information; and the compromise of the User Account credentials, and
            You shall hold Tijoree harmless from any such losses. In the event
            that these Terms and Conditions are not complied with, Tijoree
            retains the right to deny access to the Website, terminate accounts,
            remove, or change content at any time without prior warning to the
            User in question.
          </p>
          <h4>Offline e-KYC Verification</h4>
          <p>
            If you use Aadhar Offline e-KYC to register on the Website, you now
            irrevocably:
          </p>
          <ul>
            <li>
              submit a request to Tijoree or any third party service provider to
              access Your Aadhaar
            </li>
            <li>
              Information (defined below) from UIDAI to retrieve and verify
              information about Your Aadhaar Number, Aadhaar XML, Virtual ID,
              e-Aadhaar, Masked Aadhaar, Aadhaar details, demographic
              information, identity information, Aadhaar registered mobile
              number, face authentication details and/or biometric information
              (collectively referred to as “<b>Aadhaar Information</b>”).
            </li>
            <li>
              authorize Tijoree to get from UIDAI Your Aadhaar Information and
              make it available to Tijoree and/or any third party as needed to
              complete KYC verification.
            </li>
            <li>
              acknowledge that you will take all necessary steps to authenticate
              and verify your Aadhaar information.
            </li>
            <li>
              provide the necessary license and consent to use Aadhaar API
              services provided by any third
            </li>
            <li>
              party service provider, including but not limited to the
              transmission and storage of Your Aadhaar Information by Tijoree,
              as may be required by relevant laws, rules, regulations, and
              guidelines.
            </li>
            <li>
              acknowledge that You have provided the consent voluntarily and
              without being coerced to do so by Tijoree or any other party.
            </li>
            <li>
              acknowledge that by granting Tijoree permission to access your
              Aadhaar information, you fully
            </li>
            <li>
              understand and agree to assume full responsibility for any Losses
              (as defined below) resulting from such requests and actions, and
              that you will indemnify Tijoree, its representatives, employees,
              and directors from any Losses.
            </li>
            <li>
              agree that Tijoree shall not retain Your Aadhaar Information after
              the request has been processed.
            </li>
            <li>
              agree that it is Your responsibility to verify the identity of the
              person who is collecting the Aadhar Information on behalf of any
              bank or any third party service provider.
            </li>
          </ul>

          <h4>Fees and Taxes</h4>

          <p>
            According to the Services you have requested or used, as well as the
            pricing or subscription plan you have selected on the Tijoree
            Website, fees will be levied to You (the &quot;<b>Fee</b>&quot;).
            Any fee or charge or institute may be changed by Tijoree, and the
            User agrees to pay all fees and charges that are subsequently
            imposed without objection or delay.
          </p>
          <p>
            Any amount paid as Fees unless specified in these Terms and
            Conditions, will not be refunded by Tijoree.
          </p>
          <p>
            The User shall bear all applicable taxes if the Fees are subject to
            any type of use or goods and sales tax, income tax, duty or other
            governmental tax or levy.
          </p>

          <h4>Data, Audit &amp; Security</h4>

          <p>
            To identify and stop high-risk behavior and fraudulent Transactions,
            Tijoree may monitor all Transactions. It is acknowledged that
            Tijoree might work with Service Providers to support these efforts
            as well. The User Account may be suspended temporarily or
            permanently if there is any suspicious or unusual conduct occurring
            through it.
          </p>
          <p>The User and Tijoree represent and covenant that:</p>

          <ol id="l2" type="a">
            <li data-list-text="(a)">
              <p>
                they do not store card information or any related data within
                their databases or any servers they access, except in relation
                to specific, lawful purposes that comply with the RBI
                Guidelines;
              </p>
            </li>
            <li data-list-text="(b)">
              <p>
                they will abide by the data security protocols specified under
                the RBI Guidelines, including the procedures for incident
                management and reporting, In addition to performing inspections,
                risk and security assessments, and audits on the User, Tijoree
                may also impose additional conditions or restrictions on the
                User Account, including but not limited to withholding Services
                and requiring the User to upgrade its infrastructure in
                accordance with applicable laws.
              </p>
            </li>
          </ol>
          <p>
            Without obtaining the third party&#39;s prior written consent, the
            User will not sell, provide, exchange, or otherwise disclose to
            third parties or use themselves (other than for the purpose of
            concluding a Transaction or as expressly required by law) any
            personal information about any third party (whether its customer or
            otherwise), including any financial and personal information.
          </p>
          <p>
            The majority of interactive websites employ cookies to make it
            possible to retrieve user information for each visit. In various
            areas of the Tijoree Website, cookies are used to enable and improve
            the functioning and user-friendliness for the Users. The User agrees
            to the use of cookies in accordance with the Privacy Policy by using
            the Tijoree Website. Moreover, some Service Providers might employ
            cookies.
          </p>
          <p>
            You are prohibited from violating or attempting to violate the
            security of the Website. You are prohibited from doing any of the
            following:
          </p>
          <ul>
            <li>
              Accessing data not intended for You or logging onto a server or an
              account which You are not authorized to access; or
            </li>
            <li>
              Attempting to probe, scan or test the vulnerability of a system or
              network or to breach security or authentication measures without
              proper authorization; or
            </li>
            <li>
              Attempting to interfere with Service of any other user, host or
              network, including, without
            </li>
            <li>
              limitation, via means of submitting a virus to the Website,
              overloading, &#39;flooding,&#39; &#39;spamming&#39;, &#39;mail
              bombing&#39; or &#39;crashing;&#39; or
            </li>
            <li>
              Sending or transmitting junk email, unsolicited email, including
              promotions and/or advertising of products or Services; or
            </li>
            <li>
              Forging any TCP/IP packet header or any part of the header
              information in any e-mail or
            </li>
            <li>newsgroup posting; or</li>
            <li>
              Violations of system or network security, which may result in
              civil or criminal liability; or
            </li>
            <li>
              Any other action which, at our discretion, constitutes or is
              likely to constitute a threat to the security of the Website
            </li>
          </ul>

          <h4>Content of the User</h4>

          <p>
            Usage of the Services does not allow Tijoree the right to use,
            reproduce, adapt, alter, publish, or distribute any content created
            and/or saved in the User Account, unless express permission is given
            by the User. Despite the above, the User grants Tijoree permission
            to access, copy, distribute, store, transmit, reformat, or disclose
            the information in the User Account in order to provide the Services
            that the User has requested or accessed.
          </p>
          <p>
            The User is free to send or publish any content they have produced,
            whether utilising one of the Services or otherwise. Nonetheless, the
            repercussions of such content&#39;s transmission or publication are
            completely the User&#39;s responsibility. When using the Services,
            the User may receive content from third parties. This content is
            provided to the User &quot;AS IS&quot; for their information and
            personal use only. As a result, the User agrees not to use, copy,
            reproduce, distribute, transmit, broadcast, display, sell, license,
            or otherwise exploit this content for any purpose without the
            express written consent of the third party whose rights to it.
          </p>
          <p>
            If information provided on the Tijoree Website is not true,
            complete, or correct, Tijoree shall not be liable for any liability
            arising therefrom. The content on the Tijoree Website is primarily
            for general informational purposes, and it should not be relied upon
            or utilised as the only basis for choices without first contacting
            primary, more reliable, more comprehensive, or more up-to-date
            sources of information. If a User relies on any information
            available on the Website, they do so at their own risk. Tijoree
            Website might contain some historical information, which is
            inevitably out-of-date and is only offered for the User&#39;s
            reference. While we reserve the right to change the Website’s
            contents at any time, we are under no responsibility to keep it
            updated. The User acknowledges that it is his or her duty to keep
            track of modifications to the Tijoree Website.
          </p>
          <h4>Third-Party Links</h4>

          <p>
            The Website may make reference to or contain connections to
            websites, programmes, services, and resources from third parties,
            but this does not imply that We support those channels. The Company
            makes no representation or warranty of any kind regarding the
            accuracy, reliability, effectiveness, or correctness of any aspect
            of any third-party services, and as a result, the Company is not
            responsible for the content, products, or services that are
            available from third-party services. We only offer these links as a
            convenience to the User to access certain services. The User
            acknowledges entire responsibility and assumes all risk arising from
            the User&#39;s use of any third-party services. The User is
            responsible for reading and comprehending the Terms and Conditions
            and Privacy Policy that apply to the User&#39;s use of any
            third-party services.
          </p>
          <h4>Intellectual Property Rights</h4>

          <p>
            All intellectual property and proprietary rights (collectively,
            &quot;<b>IPR</b>&quot;) on the Website and Services shall
          </p>
          <p>
            remain the sole and exclusive property of Tijoree and its licensors,
            as applicable. This includes all copyrights, patents, trade secrets,
            trademarks, service marks, trade names, moral rights, and other IPR.
            Without Tijoree’s prior written approval, the IPR may not be
            assigned, copied, duplicated, distributed, transmitted, broadcasted,
            displayed, sold, licensed, uploaded, or otherwise used.
          </p>
          <h4>Restrictions</h4>

          <p>
            Except as expressly permitted under these Terms and Conditions, You
            absolutely, unconditionally and irrevocably agree not to:
          </p>
          <ul>
            <li>
              copy, adapt, convert, abridge, display, reproduce, disseminate,
              republish, re-use, upload, post,
            </li>
            <li>
              transmit or distribute any content, material or information
              presented in, furnished, available or provided on the Website,
              including without limitation for public or commercial purposes,
              and any text, images, audio and video, except as expressly
              permitted on the Website’s sharing function;
            </li>
            <li>
              sell, resell, sublicense, exploit and/or otherwise commercialize
              any Website material in whole
            </li>
            <li>
              or in part (in various formats or media including object and
              source code), in any form to any person without obtaining the
              prior written consent of the Company;
            </li>
            <li>
              sell, rent, lease, sub-lease, sub-license, assign, loan,
              distribute, time-share, or translate any
            </li>
            <li>
              content, material or information presented in, furnished,
              available or provided on the Website, in any way;
            </li>
            <li>
              make alterations to, or modifications of, the whole or any part of
              the Website, or permit the Website or any part of it to be
              combined with, or become incorporated in, any other programs;
              remove or substitute or modify any information pertaining to
              intellectual property or proprietary rights from the Website;
            </li>
            <li>
              modify, disassemble, decompile or create derivative works based on
              the whole or any part of the Website;
            </li>
            <li>
              use this Website in any way that is, or may be, damaging to this
              Website; use this Website in any way that impacts User access to
              this Website;
            </li>
            <li>
              use this Website contrary to applicable laws rules, regulations
              ordinances, guidelines, orders or
            </li>
            <li>
              judgments, or in a way that causes, or may cause, harm to the
              Website, or to any person or business entity;
            </li>
            <li>
              engage in any data mining, data harvesting, data extracting or any
              other similar activity in relation to this Website, or while using
              this Website;
            </li>
            <li>use this Website to engage in any advertising or marketing;</li>
            <li>
              access, monitor or copy any content or information of this Website
              using any robot, crawler,
            </li>
            <li>
              spider, scraper, or other automated means or any manual process
              for any purpose without the Company’s express prior written
              permission;
            </li>
            <li>
              deep-link to any portion of the Website for any purpose without
              our express written permission; take any action that imposes, or
              may impose, in the Company’s discretion, an unreasonable or
            </li>
            <li>
              disproportionately large load on the Company’s infrastructure;
            </li>
            <li>
              undertake any activity which may result in or is aimed and
              intended to cause wrongful loss to the Company;
            </li>
            <li>
              violate the restrictions in any robot exclusion headers on the
              Website or bypass or restrict circumvent other measures employed
              to prevent or limit access to the Website;
            </li>
            <li>
              &quot;frame&quot;, &quot;mirror&quot; or otherwise incorporate any
              part of the Website into any other website without Our prior
              written authorization;
            </li>
            <li>
              store or use any downloaded data in an archival database or other
              searchable database;
            </li>
            <li>
              use any &quot;page-scrape&quot;, &quot;robot&quot;,
              &quot;spider&quot; or other automatic device, program, algorithm
              or
            </li>
            <li>
              methodology, or any similar or equivalent manual process, to
              access, acquire, copy or monitor any portion of the Website or
              confidential information or any other data or information on the
              Website, or in any way reproduce or circumvent the navigational
              structure or presentation of the Website or confidential
              information or any other information, to obtain or attempt to
              obtain any materials, documents or information through any means
              not purposely made available through the Website;
            </li>
            <li>
              attempt to gain unauthorized access to any portion or feature of
              the Website, or any other
            </li>
            <li>
              systems or networks connected to the Website or to any server,
              computer, network, by hacking, password &quot;mining&quot; or by
              any other illegitimate means;
            </li>
            <li>
              probe, scan or test the vulnerability of the Website or any
              network connected to the Website
            </li>
            <li>
              or breach the security or authentication measures on the Website
              or any network connected to the Website;
            </li>
            <li>
              reverse look-up, trace or seek to trace any information of any
              User or any visitor to the
            </li>
            <li>
              Website, including any account on the Website not owned by the
              User, to its source, or exploit the Website or information made
              available or offered by or through the Website, in any way where
              the purpose is to reveal any information, including but not
              limited to personal information, other than the User’s own
              information, as provided for by the Website; make any negative,
              denigrating or defamatory statement(s) or comment(s) about the
              Company or the brand name or domain name used by the Company or
              otherwise engage in any conduct or action that might tarnish the
              image or reputation, of the Company or Users on the Website or
              otherwise tarnish or dilute any Company’s trade or service marks,
              trade name and/or goodwill associated with such trade or service
              marks, trade name or other intellectual property rights as may be
              owned or used by the Company. The User agrees that it will not
              take any action that imposes an unreasonable or disproportionately
              large load on the infrastructure of the Website or Company’s
              systems or networks, or any systems or networks connected to the
              Company;
            </li>
            <li>
              use any device, software or routine to interfere or attempt to
              interfere with the proper working
            </li>
            <li>
              of the Website or any Transaction being conducted on the Website,
              or with any other person&#39;s use of the Website;
            </li>
            <li>
              refers to any Website or URL, that in the Company’s sole
              discretion contains material that is
            </li>
            <li>
              inappropriate for the Website or contains content that would be
              prohibited or violates the letter or spirit of these Terms and
              Conditions; and
            </li>
            <li>
              forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any message or transmittal the User sends
              to the Company on or through the Website.
            </li>
            <li>
              Certain areas of this Website are restricted from access by You
              and the Company may further restrict access by You to any areas of
              this Website, at any time, in its sole and absolute discretion.
              Any username and password You may have for this Website are
              confidential and You shall be solely responsible for maintaining
              the confidentiality and security of such information and shall be
              fully responsible for all activities which occur under or from use
              of such Username(s) and password(s) and/or in connection with such
              use. You further absolutely agree that You will not permit any
              third party to browse, access or use the Website using Your
              Username(s) and password.
            </li>
            <li>
              You further agree and confirm that the Company shall not be liable
              or responsible for the activities or consequences of use or misuse
              of any information that occurs under the User account
            </li>
          </ul>
          <h4>Relationship:</h4>

          <p>
            You recognise and acknowledge that nothing in these Terms and
            Conditions should be read as establishing a partnership or joint
            venture between the User and Tijoree or as making one party in any
            way liable for the debts and obligations of the other. Additionally,
            You will not represent Yourself (online or offline) as an agent or
            representative of Tijoree, nor will you give any assurances that
            could obligate Tijoree or the Service Provider to take on or be held
            accountable for, whether directly or indirectly, any obligation
            and/or responsibility to the User or any third party.
          </p>
          <h4>Unpublished Information</h4>

          <p>
            The User agrees not to divulge any non-public information that it
            may acquire or discover on the Website or through the Services, try
            to utilise it, or use it for its own personal gain. This requirement
            will exist until the User&#39;s activity causes the previously
            private information to become known to the public. The User agrees
            to promptly and diligently notify Tijoree and cooperate fully with
            Tijoree in protecting such information to the extent permitted by
            applicable law if the User is required to divulge any such
            non-public information by order of a court or other governmental or
            legal body (or has knowledge that such an order is being sought).
          </p>
          <p>
            If it is necessary to provide any Services to the User at the
            User&#39;s request, or if necessary to ensure compliance with
            applicable law, or if we reasonably believe in good faith that it is
            necessary to (i) respond to claims made against Tijoree or to comply
            with legal process, or (ii) for fraud prevention, risk
            investigation, user support, product development, and de-bugging,
            Tijoree may access, preserve, and disclose any information about the
            User.
          </p>
          <h4>Disclaimer of Warranties &amp; Limitation of Liability</h4>

          <p>
            Tijoree shall have the right to rely on all electronic
            communications, orders, or messages given to Tijoree through the
            Service Providers in processing Transactions, including and to the
            extent that this complies with the Service Providers&#39; processing
            mechanism, any relevant laws, and the RBI Guidelines. Tijoree is not
            required to confirm the sender&#39;s identity or conduct any
            research into the authenticity of any communications, orders, or
            messages.
          </p>
          <p>
            Tijoree will make every effort to provide uninterrupted and
            error-free access to the availability of the Services. In such
            instances, Tijoree shall not be liable for any disruption of any
            Services. However, access to the application may periodically be
            suspended or limited to allow for maintenance, repairs, or the
            introduction of new facilities and Services. We reserve the right to
            choose when and what will be in software updates, which may be
            downloaded and installed by a programme automatically at any moment
            without giving the User a warning.
          </p>
          <p>
            The Services are made available as-is and as-available. With the
            exception of the implied warranties of merchantability and fitness
            for a particular purpose, Tijoree expressly disclaims all
            warranties, whether express or implied. Tijoree disclaims any
            assurance for the continuity, punctuality, security, or error-free
            operation of the Services. To the maximum extent permitted by law,
            the entire risk arising out of the User’s access of and use of the
            Website and Services, remains with the User.
          </p>
          <p>
            You expressly understand and agree that in no event the Indemnified
            Parties (as defined herein below), shall be liable to You or any
            third party for any direct, indirect, incidental, special,
            consequential, punitive or exemplary damages, expenses, costs,
            losses, damages, claims or liabilities (whether such liability is
            under contract, tort or otherwise), which You may have incurred,
            suffered and/or sustained arising from or related to these Terms and
            Conditions and/or the arrangements contemplated herein including but
            not limited to damages for loss of profits, revenue, earnings,
            business opportunity, goodwill, use, data or other intangible losses
            (hereinafter collectively referred to as “<b>Losses</b>”), even if
            the Company has been advised of the possibility of such Losses,
            arising out of or resulting indirectly or directly from:
          </p>
          <ul>
            <p>
              any failure or delay (including without limitation the use of or
              inability to use the Website or any part thereof); or
            </p>
            <p>
              Your use of this Website or any content, material or information
              thereof (including, but not limited to, Losses resulting from or
              arising out of Your reliance on the Website); or
            </p>
            <p>
              the performance or non-performance by the Company, even if we have
              been advised of the
            </p>
            <p>
              possibility of damages to such parties or any other party, or any
              damages to or viruses that may infect Your computer equipment or
              other property as the result of Your browsing, access or use of
              the Website or Your downloading of any content from the Website;
              or
            </p>
            <p>
              mistakes, omissions, interruptions, errors, defects, delays in
              operation, non-deliveries, mis-
            </p>

            <p>
              deliveries, transmissions, eavesdropping by third parties, or
              computer failure or malfunction, or damage to computer, computer
              viruses, file corruption, communication failure, network or system
              outage, theft, destruction, unauthorized access to, alteration of,
              loss of use of any record or data, and any other tangible or
              intangible loss or any failure of performance of the Website; or
            </p>
            <p>
              any agreement entered into between You and any third party (or the
              negotiations or discussions conducted in anticipation of any such
              agreement); or
            </p>
            <p>
              the termination or suspension of Your username and password by the
              Company pursuant to
            </p>
            <p>
              these Terms and Conditions, if any; or applicable laws, government
              restrictions, lockdowns, strikes, riots, wars, or sabotage,
              explosions, any natural disaster, plague, epidemic, pandemic or
              other outbreak of diseases or illnesses or health hazards or
              extreme natural events or any other force majeure event, or other
              condition beyond the Company’s reasonable control; or
            </p>
            <p>
              any subsequent offline activities arising from or relating to the
              online activities pursued by You under and / or pursuant to these
              Terms and Conditions.
            </p>
          </ul>

          <h4>Indemnification</h4>

          <p>
            Without limiting the generality or effect of the other provisions of
            these Terms and Conditions, as a condition of use, You hereby agree
            to defend, indemnify in full and hold harmless to the fullest extent
            the Company, and their respective promoters, shareholders,
            employees, directors, officers, agents, and/or their successors and
            assigns (“<b>Indemnified Parties</b>”) from and against any and all
            claims, actions, demands, liabilities, losses, costs demands, causes
            of action, damages and expenses (including attorney’s fees)
            (hereinafter referred to as “<b>Claims</b>”) of whatsoever nature
            and/or wherever made, which may be claimed, incurred, suffered
            and/or sustained by any of the Indemnified Parties or any third
            party, in connection with any Claims arising out of or in any way
            relating to:
          </p>
          <ol id="l3" type="i">
            <li data-list-text="(i)">
              <p>
                Your actions or inactions, including but not limited to, (a) the
                non-fulfillment of any of Your obligations under these Terms and
                Conditions, (b) arising out of Your violation of any applicable
                laws, rules, regulations, ordinances, guidelines, orders and
                judgments including but not limited to intellectual property
                rights, (c) payment of statutory dues and taxes, (d) claims
                relating to libel, defamation, violation of rights of privacy or
                publicity, or (e) loss of use and/or accessibility of the
                Website,
              </p>
            </li>
            <li data-list-text="(ii)">
              <p>
                Your browsing, access and usage of the Website, and/or
                engagement in transactions on the Website
              </p>
            </li>
            <li data-list-text="(iii)">
              <p>
                any passing off or alleged passing off or infringement or
                alleged infringement of the Company’s and any third party’s
                intellectual and/or informational property;
              </p>
            </li>
            <li data-list-text="(iv)">
              <p>
                breach by the Users, or by any person acting on behalf of the
                User of any representations, warranties, confirmations,
                undertakings, covenants, obligations or terms contained in these
                Terms and Conditions and/or the Privacy Policy or any other
                policy referred to on the Website; and
              </p>
            </li>
            <li data-list-text="(v)">
              <p>
                any and all actions, suits, proceeding or claims, relating to
                the foregoing sub – paras (i) to (iv).
              </p>
            </li>
          </ol>

          <p>
            You shall forthwith on demand make payment to the concerned
            Indemnified Party, within seven days of receipt of a written demand
            from the Indemnified Party, without any dispute or delay.
          </p>

          <p>
            You shall not settle any such claim without the written consent of
            the applicable Indemnified Party.
          </p>

          <p>
            For the avoidance of doubt, You confirm and warrant that the
            aforementioned rights and remedies of the Indemnified Parties are
            without prejudice to the Indemnified Parties’ other rights and
            remedies as stipulated in these Terms and Conditions and also
            without prejudice to all other rights and
          </p>

          <p>
            remedies, which the Indemnified Parties may have, including against
            You, in law or equity or otherwise.
          </p>
          <p>
            You hereby agree to expressly, absolutely and unconditionally (i)
            release and discharge the Indemnified Parties from any and all
            costs, damages, liabilities or other consequences as a result of any
            actions/inactions of the Company’s authorized representatives or
            other personnel and (ii) waive any claims or demands (whether known
            or unknown) against the Indemnified Parties that the User may have
            in this behalf, including under any statute, contract or otherwise.
          </p>
          <h4>Conveying</h4>

          <p>
            As part of using the Services, User may get emails, SMS messages,
            WatsApp, calls using pre recorded messages or artificial voice, push
            notification sent via mobile application from Tijoree. offers,
            discounts, and general information in order to provide feedback on
            User&#39;s services. The User is aware that the ability to stop
            receiving non-service-related communications can be found in the
            User Account settings or by sending an email to{" "}
            <a
              href="mailto:support@tijoree.money"
              class="a"
              target="_blank"
              rel="noreferrer"
            >
              support@tijoree.money
            </a>
            , and by using the Service, the User expressly agrees to receive
            such communications. The User shall understand that the Tijoree SMS
            service is an extra amenity offered for the User&#39;s convenience
            and that it may be subject to error, omission, and/or inaccuracy.
          </p>
          <h4>Complaints</h4>

          <p>
            We shall forward any general complaints that is received about a
            User in relation to their use of the Website to the User&#39;s
            registered email address. Within 24 (twenty-four) hours of receiving
            the complaint as forwarded by Tijoree, the User must reply to it
            directly and include Tijoree as a recipient in all correspondence.
            In the event that the User does not reply to the complainant within
            24 (twenty- four) hours of the date of Tijoree’s email to the User,
            We reserve the right, in our sole discretion, to choose to give the
            complainant access to the User&#39;s contact information so that
            they may take legal action against the User.
          </p>
          <p>
            The User shall be aware that by failing to reply to the forwarded
            complaint within the allotted 24 (twenty-four) hours, the User is
            giving Tijoree permission to disclose the User&#39;s identity and
            contact information. Despite what has been said thus far, the User
            acknowledges and accepts that Tijoree may disclose any information
            about the User if it determines, in its sole discretion, that such
            disclosure is necessary in the interest of justice; and Tijoree may
            withhold or appropriate any settlements owed to the Merchants in
            order to ensure compliance with applicable laws on the basis of
            reasonable suspicion.
          </p>
          <p>
            It is acknowledged that a Transaction is completely between the User
            and its Customer and that Tijoree makes no promises, warranties, or
            commitments regarding the User&#39;s delivery level. In no way does
            a User&#39;s use of the Tijoree Website imply that Tijoree has
            endorsed that User. Dispute resolution between Customers, users, and
            payment participants shall not be the duty of Tijoree.
          </p>
          <h4>Suspension and Termination of accessibility</h4>

          <p>
            Tijoree reserves the right to suspend or terminate a User&#39;s
            access to any or all of its Services in the event that (a) the User
            violates these Terms and Conditions; (b) the User engages in or is
            suspected of engaging in any illegal, fraudulent, or abusive
            activity; or (c) the User provides any information that is untrue,
            inaccurate, not current, or incomplete, or Tijoree has reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, or not in accordance with these Terms and
            Conditions.
          </p>
          <p>
            If Tijoree notices that the User is engaging in any suspicious or
            unusual activity on the Tijoree Website, Tijoree may, in its sole
            discretion, suspend the User&#39;s access to the Tijoree Website
            temporarily or permanently and/or take any other steps necessary to
            reduce risk, including but not limited to withholding funds or
            returning funds to the source from which the payment was made.
          </p>
          <h4>Governing Law, Jurisdiction and Disputes</h4>

          <p>
            Without prejudice to the provisions mentioned below, these Terms and
            Conditions are subject to the laws of India and any disputes arising
            out of or in connection with the Terms and Conditions will be
            subject to the exclusive jurisdiction of the competent courts at
            Mumbai, India.
          </p>
          <p>
            In the event of a dispute or claim arising out of or related to the
            Terms and Conditions, Tijoree and the User acknowledge and agree
            that: (a) the Transaction logs kept by Tijoree will be the only
            source of information to verify the accuracy of such Transactions;
            (b) such logs will be fully binding as evidence for the purposes of
            adjudicating the said dispute or claim; and (c) the said dispute or
            claim shall be settled by binding arbitration in accordance with the
            Arbitration and Conciliation (Amendment) Act, 1996.
          </p>
          <p>
            You acknowledge that this Website is operated, controlled and
            administered solely in India. While the Company makes every effort
            to ensure that the Website operates in a legally compliant manner,
            You, the User expressly acknowledge and agree that the Company shall
            not be responsible for complying with the laws of different
            jurisdictions (other than India) where You are based out of. You
            specifically waive any rights which are granted to You under any
            foreign laws and further acknowledge the adequacy and sufficiency of
            the laws of India to protect inter alia Your privacy rights. The
            Company makes no representation that the Website is appropriate or
            available for use outside the Republic of India.
          </p>
          <p>
            A valid, enforceable provision that most closely matches the intent
            of the original provision will be deemed to have superseded it in
            the event that any part of these Terms and Conditions is found to be
            unlawful, unenforceable, or invalid under applicable law, including,
            but not limited to, the warranty disclaimers and liability
            limitations set forth herein.
          </p>

          <h4>Customer Grievance Redressal</h4>
          <p>
            For any enquiry, complaints or concerns, the User can, by writing,
            contact to the Grievance Officer at the coordinates mentioned below:
          </p>
          <p>
            Grievance Redressal Officer for Technical Issues and Commercials
            issues.
          </p>
          <p>Name: Raghav Chaturvedi</p>
          <p>Designation: Director</p>
          <p>
            Email:{" "}
            <a
              href="mailto:grievances@tijoree.money"
              target="_blank"
              rel="noreferrer"
            >
              grievances@tijoree.money
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsConditions;
