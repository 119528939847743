import React, { useEffect } from "react";
import Footer from "../../common/Footer";

const RefundCancellation = () => {
  useEffect(() => {
    document.title = "Tijoree";
    // let descMeta=document.querySelector('meta[name="description"]')
    // descMeta.setAttribute("content", 'A B2B payments platform to optimize all of your business spends that combines the power of commercial credit cards and customisable tech to help you optimise your treasury returns and working capital.');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="page-wrapper">
      <section id="text-content-page" className="text-content-page">
        <div className="container-md">
          <h1>Refunds, Chargeback, and Cancellation</h1>

          <p>
            Tijoree shall endeavor to instruct the Nodal Bank to transmit the
            Settlement Amount from the Nodal Account to the Vendor's Bank
            Account or in Customer Bank Account in case the Customer is
            accepting payments from Vendors within the time-period prescribed by
            RBI commencing from the date of Completion of Transaction as
            separately mutually agreed. If the Customer opts for Tijoree
            Services using Tijoree Services Product, the Customer understands
            that the Transaction shall be completed only upon receipt of the
            Transaction Amount in the Nodal Account from the Acquiring and/or
            Issuing Banks and reconciliation of the same by the Acquiring and/or
            Issuing Banks, Tijoree and the Nodal Bank ("Tijoree Completion of
            Transaction").
          </p>

          <p>
            The transmission of the Settlement Amount to the Vendor
            Bank/Utility/GST/Rental Service provider bank account shall be
            subject to reconciliation of the Customer Charge by the Acquiring
            and/or Issuing Banks, Tijoree, and the Nodal Bank after actual
            receipt of the Transaction Amount in the Nodal Account. The Customer
            shall be solely responsible for all payments with respect to refunds
            and Chargebacks and Tijoree shall not be liable for any claims,
            disputes, or penalties which may arise in connection with such
            refunds or Chargebacks. The Customer agrees to indemnify Tijoree in
            respect of any claims, disputes, penalties, costs and expenses
            arising directly or indirectly in relation to refunds or Chargebacks
            for all Transactions initiated and instructed through the Tijoree
            Platform or through other medium. Tijoree will not be liable in any
            way for any chargeback or refund claims, arising specifically from
            the Virtual Cards program.
          </p>

          <p>
            Notwithstanding anything contained herein, the Customer hereby fully
            confirms and agrees that Tijoree, card network, Acquiring and/or
            Issuing Bank and Nodal Bank reserve the right to reject payments
            with respect to any Customer Charge for reasons including but not
            limited to unlawful, unenforceable, doubtful or erroneous
            Transaction, Chargeback, refund, fraud, suspicious activities, card
            Authentication and Authorization issues, overpayment made due to
            mathematical errors or otherwise, penalties incurred, Transaction
            related issues, issues related to Delivery, Customer Charge or
            Product, if the Transaction was not made in accordance with the
            requirements of Tijoree and/or the Acquiring and/or Issuing Banks
            and/or Card Association.
          </p>

          <p>
            In the event of rejection of payment with respect to Customer
            Charge, Chargeback, refunds or other Outstanding Amounts due to
            Tijoree, Acquiring and/or Issuing Bank, Tijoree and/or the Acquiring
            and/or Issuing Bank reserve the right to reverse the credit given to
            the Customer or Vendor or Vendors Bank Account or set-off the
            payment amount rejected, Chargeback or refunded or the Outstanding
            Amount against Settlement Amount payable to the Vendor or Vendors or
            Customer or utility service provider. Tijoree and/or Acquiring
            and/or Issuing Banks may also deduct the payment amount due from
            future Settlement Amounts payable to the Vendor or Vendors or
            Customer with respect to subsequent Transactions and/or ask to pay
            before any future transaction processing. All Settlement Amount due
            to the Vendor(s), Customer or utility service provider may be
            suspended or delayed till such time as Tijoree, the Acquiring and/or
            Issuing Banks and/or Nodal Bank deems fit, if (a) the Customer or a
            third party commits any fraud or violates any law or legal
            requirement; (b) Tijoree and/or the Acquiring and/or Issuing Banks
            have reasons to believe that a fraud has been committed by the
            Customers, (c) the Customer has excessive pending Chargebacks or
            poses high Chargeback and/or refund Risk; or (d) for any other
            reasonable reasons. Tijoree reserves the right to reject or de-board
            any Vendor/service provider found violating Acquiring/Issuing bank
            norms & guidelines and/or for OFAC/Sanctions/AML purposes.
          </p>

          <p>
            In addition, cancellation, and suspension, shall be subject to the
            Policy of the Card Issuer Bank, as set out on the website and
            updated from time to time
          </p>

          <p>
            The User is responsible for all information provided in respect of
            the User Account, including the correctness of details for payment
            of your credit card bill and all charges that result from these
            payments. Tijoree shall not be responsible for any payment for an
            incorrect Vendor account or bill payment number.
          </p>

          <h6 className="gap-bottom">Review of the Policy</h6>

          <p>
            This policy would be reviewed on a periodic basis for any update or
            change in regulatory guidelines.
          </p>

          {/* <ul>
            <li>Tijoree collects and processes a variety of categories of Users' Personal Information in order to determine Users' eligibility and make the products and services available to them on the website, including the following:</li>
            <li>Personal information such as date of birth, age, marital status, birthplace, nationality, mother tongue; </li>
            <li>Contact information like name, address, telephone, and email address; </li>
            <li>Identifiers that are issued by the government, such as PAN and GST data; </li>
            <li>Financial data, such as bank account information, credit/debit card information, billing details, tax details, and payroll details (as applicable);</li>
            <li>Photographs and signature copies; </li>
            <li>Information which would help determine Users eligibility to avail the services offered on the Website; and</li>
            <li>Any other information that Tijoree is required to collect as per specific mandate from any bank, or under applicable law, regulation, rule or statute in India.</li>
          </ul>
          <p>In addition to the aforementioned, Tijoree may gather any additional consent or information for the mobile application in order to deliver a seamless mobile experience, such as but not limited to: SMS access for automatic OTP verification, camera/gallery/contacts access, and any other permissions necessary to enable specific functionalities within the mobile application.</p>
          <h6 className="gap-bottom">Sharing of Information and third parties disclosures</h6>
          <p>As a technology aggregator, Tijoree provides services through the Website/App. In light of this, Tijoree may be forced to disclose User information in order to grant Users access to its services, to meet its legal duties, and to prevent, detect, mitigate, and look into fraudulent or unlawful acts relating to its services. Tijoree does not share Your Personal Information with third parties unless expressly permitted under the terms contained herein.</p>
          <p>If obliged to do so by law or in the good faith belief that such disclosure is advisable in order to answer to subpoenas, court orders, or other legal process, Tijoree may disclose Personal Information.</p>
          <p>In the good faith belief that such disclosure is reasonably necessary to: (a) enforce the Terms and Conditions or this Privacy Policy; (b) respond to claims that an advertisement, posting, or other content violates the rights of a third party; or (c) protect the rights, property, or personal safety of Users or the public, Tijoree may disclose Personal Information to law enforcement offices, third party rights owners, or others.</p>
          <p>To third parties who perform services for Us, We may divulge Personal Information as long as they abide by the confidentiality responsibilities outlined in this Privacy Policy. These third parties are only permitted to use Users' Personal Information as required to deliver the website services that Users have requested or accessed. These services could include offering cloud computing infrastructure, reporting website crashes, analyzing website usage patterns to better serve Users, and offering customer care or account management.</p>
          <h4>Data Retention</h4>
          <p>As long as the User's account is active or as long as it's necessary to provide Website services, Tijoree will keep track of the Users' information. In order to fulfil its legal duties, settle disputes, or pursue other business objectives, Tijoree must keep and use the data it has gathered. Tijoree is not obligated to keep the Users' information if the Users deactivate, cancel, or unsubscribe from their account with Tijoree. In order to maintain compliance with applicable regulations, Tijoree may keep Users' information for a period of twelve months after they cancel, deactivate, or unsubscribe from their Tijoree accounts.</p>
          <h4>Data Security</h4>
          <p>The Users are aware of all security concerns, including the potential for third parties to intercept any online transactions or account statements they make or for them to learn the details of their transactions or statements. Users acknowledge and agree that they will not in any way hold Tijoree responsible for this. It is Your responsibility and risk to use and keep any information, including but not limited to the credit card number, PIN, password, account information, transaction history, account balances, and any other information accessible or kept on the User's Device. Furthermore, Users recognise that not all content or communications from or to their website may be encrypted by Tijoree.</p>
          <h4>Collection of sensitive personal data or information by Tijoree</h4>
          <p>In accordance with the rules of this Privacy Policy, Users have the right to withdraw their consent at any time in writing by sending an email to Tijoree at grievances@tijoree.money. Please be aware, though, that withdrawal of consent will only be effective going forward rather than being retrospective.</p>
          <p>To access, examine, change, or correct their sensitive personal data or information, or to withdraw their consent to provide such data or information, You may write to Tijoree at grievances@tijoree.money. The veracity of the information submitted by Users is not guaranteed by Tijoree.</p>
          <p>The Users understand and agree that Tijoree may limit or deny their ability to access, alter, or withdraw their consent to provide sensitive personal data or information as may be necessary to comply with any applicable laws, law enforcement requests, or legal processes.</p>
          <h4>Links to other sites</h4>
          <p>On the Website, there may be links to third-party advertisements, third-party websites, or other third party electronic communication service run by a third party. Unless otherwise specifically stated on the Website, such links may not be managed by, related with, or associated with Tijoree.</p>
          <p>Tijoree disclaims any liability for any transmissions of any kind that Users may receive from third-party websites. Tijoree does not control or vouch for the truth, integrity, or caliber of any information, data, text, software, music, sound, photographs, graphics, videos, messages, or other materials found on such third-party websites, nor does it make any representations regarding the privacy practices or policies of such third parties or the terms of use of such third-party websites. The inclusion or omission of a third party website, its operator, or any of its content does not constitute sponsorship by Tijoree of those entities. It is advised that Users study the privacy policies of such third party websites prior to accessing such websites as the information given by Users to such third party websites shall be managed in line with such third party websites' privacy policies.</p>
          <h4>Acknowledgements</h4>
          <p>By the usage of Website/ App by the Users, the Users acknowledge that Tijoree will not be obligated to return their application or copies of any documents they have submitted to them upon their request. This applies only to the extent authorized by applicable legislation. It is against the law and a breach of these terms to gain unauthorized access to the Website. The Users acknowledge that Tijoree's offered interface is the only way to access the website.</p>
          <h6 className="gap-bottom">The Users acknowledge and agree that this Privacy Policy:</h6>
          <ul>
            <li>Is clear, accessible, and provides statements of the Tijoree's information security policies and practices; </li>
            <li>Provides for disclosure of information; </li>
            <li>Provides for the various types of personal or sensitive personal data of information to be collected; </li>
            <li>Provides for the purposes of collection and usage of the information; and </li>
            <li>Provides for reasonable security practices and procedures. </li>
            <li>If the Users have any questions in respect of this Privacy Policy, You may write to the Grievance Officer appointed as per the provisions of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011:</li>
          </ul>
          <div className="grevience">
            <h6>Grievance & Redressal Officer:</h6>
            <div>Name: Raghav Chaturvedi </div>
            <div>Designation: Director </div>
            <div>Email: grievances@tijoree.money </div>
            <div>Tijoree Invest Private Limited</div>
          </div>
          <div className="content-footer">
            <div>*Last Updated: 14/03/2023</div>
            <div>*Please note that this is not exhaustive, and an exhaustive version will be updated on 23/03/2023</div>
          </div> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default RefundCancellation;
