import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../common/Footer";

// Images imported from assets
import money from "../../assets/img/money.svg";
import piggy from "../../assets/img/piggy.svg";

const Features = () => {
  useEffect(() => {
    document.title = "Tijoree";
    let descMeta = document.querySelector('meta[name="description"]');
    descMeta.setAttribute(
      "content",
      "A B2B payments platform to optimize all of your business spends that combines the power of commercial credit cards and customisable tech to help you optimise your treasury returns and working capital."
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="page-wrapper">
      <section id="inner-banner">
        <div className="container-md">
          <div className="banner-text features">
            <h6>
              <Link to={"/"}>Home</Link> &gt; Features
            </h6>
            <h1>Business Banking</h1>
            <p>
              Empower your business with all the right tools to accept online
              payments
            </p>
          </div>
        </div>
      </section>
      <section id="features">
        <div className="container-md">
          <h3>
            Features <span className="purple">Comparison</span>
          </h3>
          <div className="features-list">
            <div className="row">
              <div className="col-md-6">
                <div className="list active">
                  <div className="top-icon d-flex align-items-center justify-content-center">
                    <img src={money} alt="tijoree" />
                  </div>
                  <div className="title">
                    <h4>Tijoree</h4>
                    <h5>Current Account</h5>
                  </div>
                  <ul>
                    <li>Cashback & Rewards on your current A/C</li>
                    <li>Dynamic Dashboard</li>
                    <li>Lower Account Balance availability</li>
                    <li>Rewards on transactions</li>
                    <li>Overdraft facility</li>
                    <li>Branch Support</li>
                    {/* <li>Customer Service Team 24*7</li> */}
                    <li>Zero cooling period after adding beneficiaries</li>
                    <li>Addition of multiple beneficiaries for payments</li>
                    <li>Cheque/Card/DD/PO facilities</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="list">
                  <div className="top-icon  d-flex align-items-center justify-content-center">
                    <img src={piggy} alt="tijoree" />
                  </div>
                  <div className="title">
                    <h4>Standard</h4>
                    <h5>Current Account</h5>
                  </div>
                  <ul>
                    <li className="disabled">
                      Cashback & Rewards on your current A/C
                    </li>
                    <li className="disabled">Dynamic Dashboard</li>
                    <li>Lower Account Balance availability</li>
                    <li className="disabled">Rewards on transactions</li>
                    <li>Overdraft facility</li>
                    <li>Branch Support</li>
                    {/* <li className="disabled">Customer Service Team 24*7</li> */}
                    <li className="disabled">
                      Zero cooling period after adding beneficiaries
                    </li>
                    <li className="disabled">
                      Addition of multiple beneficiaries for payments
                    </li>
                    <li>Cheque/Card/DD/PO facilities</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Features;
