import "aos/dist/aos.css";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AOS from "aos";
import Footer from "../../../common/Footer";
import React, { useEffect } from "react";

import cardCorner from "../../../assets/img/card-corner.png";
import CccChangeTxnFeeMobileBanner from "../../../assets/img/ccc-change-txn-fee-mobile-banner.png";
import CccCreateCardPhone from "../../../assets/img/ccc-create-card-phone.png";
import CccIntuitQuickBanner from "../../../assets/img/ccc-intuit-quickbook-banner.png";
import CccInvoiceBanner from "../../../assets/img/ccc-invoice-discounting-banner.png";

import CccReceiptsBanner from "../../../assets/img/ccc-receipts-banner.png";
import CccSapBanner from "../../../assets/img/ccc-spa-banner.png";
import CccTallyBanner from "../../../assets/img/ccc-tally-banner.png";
import CccVendorMobileBanner from "../../../assets/img/ccc-vendor-payout-mobile-banner.png";
import CccVendorTxnFeeBanner from "../../../assets/img/ccc-change-txn-fee-banner.png";
import CccVirtualCardBanner from "../../../assets/img/ccc-virtual-card-banner.png";
import CccVirtualCardEmpBanner from "../../../assets/img/ccc-virtual-card-employee-banner.png";
import CccVirtualCardEmpMobileBanner from "../../../assets/img/ccc-virtual-card-employee-mobile-banner.png";
import CccVirtualCardMobileBanner from "../../../assets/img/ccc-virtual-card-mobile-banner.png";
import CccZohoBanner from "../../../assets/img/ccc-zoho-banner.png";
import CorporateCreditCardBanner from "../../../assets/img/ccc-home-page.png";
import vendorPayoutImage from "../../../assets/img/vendor-payout.png";

import cardCornerLazy from "../../../assets/img/webp/card-corner.webp";
import CccChangeTxnFeeMobileBannerLazy from "../../../assets/img/webp/ccc-change-txn-fee-mobile-banner.webp";
import CccCreateCardPhoneLazy from "../../../assets/img/webp/ccc-create-card-phone.webp";
import CccInvoiceBannerLazy from "../../../assets/img/webp/ccc-invoice-discounting-banner.webp";

import CccMaxTreasuryEarningBannerLazy from "../../../assets/img/webp/ccc-max-treasury-earnings.webp";

import CccReceiptsBannerLazy from "../../../assets/img/webp/ccc-receipts-banner.webp";
import CccVendorMobileBannerLazy from "../../../assets/img/webp/ccc-vendor-payout-mobile-banner.webp";
import CccVendorTxnFeeBannerLazy from "../../../assets/img/webp/ccc-change-txn-fee-banner.webp";
import CccVirtualCardBannerLazy from "../../../assets/img/webp/ccc-virtual-card-banner.webp";
import CccVirtualCardEmpBannerLazy from "../../../assets/img/webp/ccc-virtual-card-employee-banner.webp";
import CccVirtualCardEmpMobileBannerLazy from "../../../assets/img/webp/ccc-virtual-card-employee-mobile-banner.webp";
import CccVirtualCardMobileBannerLazy from "../../../assets/img/webp/ccc-virtual-card-mobile-banner.webp";
import CorporateCreditCardBannerLazy from "../../../assets/img/webp/ccc-home-page.webp";
import CccMainBannerMobileLazy from "../../../assets/img/webp/ccc-home-mobile-page.webp";
import CccStatutoryPaymentBannerLazy from "../../../assets/img/webp/ccc-statutory-payment.webp";

import apple from "../../../assets/img/apple.svg";
import CccPoweredByInduslndBank from "../../../assets/img/ccc-induslnd-bank-powered-by.svg";
import CccPoweredByVisa from "../../../assets/img/ccc-visa-powered-by.svg";
import CccStatutoryPaymentBanner from "../../../assets/img/ccc-statutory-payment.svg";
import CccMaxTreasuryEarningBanner from "../../../assets/img/ccc-max-treasury-earnings.svg";
import playstore from "../../../assets/img/playstore.svg";
import CccMainBannerMobile from "../../../assets/img/ccc-home-mobile-page.svg";

const CorporateCreditCard = () => {
  useEffect(() => {
    document.title = "Tijoree";
    let descMeta = document.querySelector('meta[name="description"]');
    descMeta.setAttribute(
      "content",
      "Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking."
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({
      easing: "ease-out-back",
      duration: 700,
      once: true,
      disable: "mobile",
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> Corporate Credit Card | Tijoree</title>
        <meta
          name="description"
          content=" Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking."
        />
      </Helmet>
      <div className="page-wrapper">
        {/* CCC Home page*/}
        <section
          id="inner-top-section"
          className="inner-top-section grey-bg-2 payment-collection-banner ccc-main-page"
        >
          <div className="container-md">
            <div className="inner-content">
              <div className="row">
                <div className="page-title">Corporate Credit Card</div>

                <div className="row justify-content-between">
                  <div className="col-md-6">
                    <h2 className="size40">
                      Streamline Expenses, Amplify Savings with our Corporate
                      Credit Card
                    </h2>
                    <div className="action-btns d-lg-block d-md-block d-sm-none">
                      <Link to={"/form"} className="button btn-yellow">
                        Apply for a Corporate Credit Card
                      </Link>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <p className="py-3 py-lg-0 fst-italic">Powered By</p>
                    <div className="imageContainer">
                      <img src={CccPoweredByVisa} alt="Powered By Visa" />
                      {/* <img
                        src={CccPoweredByInduslndBank}
                        alt="Powered By Indusland Bank"
                      /> */}
                    </div>
                    <div className="action-btns d-lg-none d-md-none d-sm-block">
                      <Link to={"/form"} className="button btn-yellow">
                        Apply for a Corporate Credit Card
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-img text-center ccc-main-img">
              <picture>
                <source
                  type="image/webp"
                  srcSet={CorporateCreditCardBannerLazy}
                ></source>
                <source
                  type="image/png"
                  srcSet={CorporateCreditCardBanner}
                ></source>
                <img
                  className="desktop"
                  alt="Corporate Credit Card"
                  src={CorporateCreditCardBanner}
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  srcSet={CccMainBannerMobileLazy}
                ></source>
                <source type="image/png" srcSet={CccMainBannerMobile}></source>
                <img
                  className="mobile"
                  alt="Corporate Credit Card"
                  src={CccMainBannerMobile}
                />
              </picture>
            </div>
          </div>
        </section>

        {/* White Background */}
        <div className="first-half-background"></div>
        <section className="section1">
          {/* Background Shape Image */}
          {/* <picture>
            <img className="background-shape1" alt="Corporate Credit Card" src={cccBackgroundShap} />
          </picture> */}

          <div className="vendor-payout-section">
            <div className="container-md justify-content-center px-0">
              <section
                id="monitor-spending"
                className="monitor-spending vendor-payout-banner"
              >
                <div className="container-md">
                  <div className="grey-pattern-full hover-box ">
                    <div className="content-box">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="vendor-payments-text">
                            <h3 className="size40">Vendor Payout</h3>
                            <p className="size20 py-3 py-lg-0">
                              Make hassle-free transfers to your vendors’ bank
                              accounts using Tijoree’s Corporate Credit Card,
                              and earn up to ~1.5% back on every transaction
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 ccc-vendor-img">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={vendorPayoutImage}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={vendorPayoutImage}
                            ></source>
                            <img
                              className="desktop card-img hover-img"
                              alt="Vendor Payments"
                              src={vendorPayoutImage}
                            />
                          </picture>
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={CccVendorMobileBannerLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={CccVendorMobileBanner}
                            ></source>
                            <img
                              className="mobile card-img hover-img"
                              alt="Vendor Payments"
                              src={CccVendorMobileBanner}
                            />
                          </picture>
                        </div>
                        <div className="cardcorner">
                          <span className="overflow-hidden">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet={cardCornerLazy}
                              ></source>
                              <source
                                type="image/png"
                                srcSet={cardCorner}
                              ></source>
                              <img
                                className="card-img hover-img"
                                alt="Vendor Payment"
                                src={cardCorner}
                              />
                            </picture>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="spacer-250"></div>

              <div className="optimize-info-box row">
                <div className="col-lg-6">
                  <div className="raised-block">
                    <h3 className="pb-4">Optimize your Supply Chain</h3>
                    <p className="optimize-text">
                      Our platform in collaboration with partner banks helps you
                      get the lowest borrowing rates for supplier payments. Grow
                      your business without worrying about your balance sheet
                    </p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="raised-block">
                    <h3 className="pb-4">
                      Manage all Vendors in a Single Location
                    </h3>
                    <p className="optimize-text">
                      Track your vendors and invoices, and seamlessly use our
                      Corporate Credit Card to pay against those invoices
                    </p>
                  </div>
                </div>
              </div>

              <div className="payment-heading d-flex justify-content-center">
                <div className="col-lg-9 text-center">
                  <h1>Enjoy up to 45 days of Credit</h1>
                  <p className="size20 pt-4">Never miss a payment</p>
                </div>
              </div>

              {/* Get Full controller Text + Banner */}
              <div className="row align-items-center justify-content-between hover-out txn-fees-box">
                <div className="col-md-5 mobile-order-2 py-5 py-lg-0">
                  <div className="payment-text text-center text-md-start">
                    <h2 className="col align-self-center px-lg-5">
                      Get full control over transaction fees
                    </h2>
                    {/* <h5 className="mt-4">Know More</h5> */}
                  </div>
                </div>

                <div className="col-md-6 mobile-order-1 align-items-center d-flex justify-content-center justify-content-md-start">
                  <div className="payment-image">
                    <div className="image-wrap">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={CccVendorTxnFeeBannerLazy}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={CccVendorTxnFeeBanner}
                        ></source>
                        <img
                          className="desktop hover-img"
                          alt="Customize your Reminder"
                          src={CccVendorTxnFeeBanner}
                        />
                      </picture>
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={CccChangeTxnFeeMobileBannerLazy}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={CccChangeTxnFeeMobileBanner}
                        ></source>
                        <img
                          className="mobile hover-img"
                          alt="Customize your Reminder"
                          src={CccChangeTxnFeeMobileBannerLazy}
                        />
                      </picture>
                    </div>
                  </div>
                </div>

                {/* <div className='col-md-6  mobile-order-2 align-items-center'>
                <div className=' payment-image'>
                  <div className="image-wrap">

                    <picture className="align-items-center">
                      <source type="image/webp" srcSet={CccVendorTxnFeeBannerLazy}></source>
                      <source type="image/png" srcSet={CccVendorTxnFeeBanner}></source>
                      <img className="desktop hover-img" alt="Customize your Reminder" src={CccVendorTxnFeeBanner} />
                    </picture>
                    <picture>
                      <source type="image/webp" srcSet={CccChangeTxnFeeMobileBannerLazy}></source>
                      <source type="image/png" srcSet={CccChangeTxnFeeMobileBanner}></source>
                      <img className="mobile hover-img" alt="Customize your Reminder" src={CccChangeTxnFeeMobileBannerLazy} />
                    </picture>

                  </div>
                </div>
              </div> */}
              </div>

              {/* Maximize Treasury Earnings Banner */}
              <section
                id="monitor-spending"
                className="monitor-spending max-treasury-earning-box"
              >
                <div className="container-md">
                  <div className="grey-pattern-full hover-box">
                    <div className="content-box">
                      <div className="row">
                        <div className="col-md-6 vendorimg mobile-order-2">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={CccMaxTreasuryEarningBannerLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={CccMaxTreasuryEarningBanner}
                            ></source>
                            <img
                              className="desktop card-img hover-img"
                              alt="Vendor Payments"
                              src={CccMaxTreasuryEarningBanner}
                            />
                          </picture>
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={CccMaxTreasuryEarningBannerLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={CccMaxTreasuryEarningBanner}
                            ></source>
                            <img
                              className="mobile card-img hover-img"
                              alt="Vendor Payments"
                              src={CccMaxTreasuryEarningBanner}
                            />
                          </picture>
                        </div>

                        <div className="col-md-6 mobile-order-1">
                          <div className="max-treasury-earning-text">
                            <h3 className="size40">
                              Maximize Treasury Earnings
                            </h3>
                            <p className="size20 pe-lg-5 py-lg-4">
                              Earn a guaranteed cashback of upto 1.5% on every
                              transaction
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <section id="get-paid" className="get-paid statutory-payments-banner">
          <div className="container-md">
            <div className="gradient-card hover-box">
              <div className="inner-content">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={CccStatutoryPaymentBannerLazy}
                  ></source>
                  <source
                    type="image/png"
                    srcSet={CccStatutoryPaymentBanner}
                  ></source>
                  <img
                    className="overview-frame desktop"
                    alt="Simplified Banking"
                    src={CccStatutoryPaymentBanner}
                  />
                </picture>

                <div className="columns-box">
                  <div className="row">
                    <div className="col-md-6 order-0">
                      <h3 className="size40">Make Statutory Payments</h3>
                      <p className="size20">
                        Without unnecessary manual processes and in timely
                        fashion
                      </p>
                    </div>

                    <div className="col-md-12 order-1 action ele-order py-5 py-lg-0">
                      <Link className="button btn-white oca fw-bold" to="/form">
                        Contact us
                      </Link>
                    </div>

                    <div className="col-md-4 text-center order-2">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={CccStatutoryPaymentBannerLazy}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={CccStatutoryPaymentBanner}
                        ></source>
                        <img
                          className="overview-frame mobile"
                          alt="Simplified Banking"
                          src={CccStatutoryPaymentBanner}
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="virtual-card-section">
          {/* Invoice Discounting + Banner */}
          <div className="container-md">
            <div className="row justify-content-between hover-out invoice-discouting-box">
              {/* <div className="col-md-5 mobile-order-2 py-5 py-lg-0 payment-text text-center text-md-start">
                <h2 className="col align-self-center">Invoice Discounting</h2>
                <p className="size20 pt-3">
                  Get the lowest invoice discounting rates with our partner banks
              </p> */}
              {/* <h5 className="">Know More</h5> */}
              {/* </div> */}

              {/* <div className="col-md-5 mobile-order-2 py-5 px-lg-5 py-lg-0 payment-text d-flex align-items-center justify-content-center justify-content-md-start  text-center text-md-start">
                <div>
                  <h2 className="col align-self-center">Invoice Discounting</h2>
                  <p className="size20 pt-3">
                    Get the lowest invoice discounting rates with our partner
                    banks
                  </p>
                  <h5 className="">Know More</h5>
                </div>
              </div> */}

              {/* <div className="col-md-1"></div>
              <div className="col-md-6  mobile-order-1 text-md-center">
                <div className="payment-image align-self-center">
                  <div className="image-wrap">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={CccInvoiceBannerLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={CccInvoiceBanner}
                      ></source>
                      <img
                        className="desktop hover-img"
                        alt="Customize your Reminder"
                        src={CccInvoiceBanner}
                      />
                    </picture>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={CccInvoiceBannerLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={CccInvoiceBanner}
                      ></source>
                      <img
                        className="mobile hover-img"
                        alt="Customize your Reminder"
                        src={CccInvoiceBanner}
                      />
                    </picture>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <section className="section2">
            {/* <picture>
            <img className="background-shape1" alt="Corporate Credit Card" src={CccShape2Bg} />
          </picture> */}
            <section id="get-paid" className="get-paid virtual-card-banner">
              <div className="container-md">
                <div className="gradient-card hover-box">
                  <div className="inner-content">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={CccVirtualCardBannerLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={CccVirtualCardBanner}
                      ></source>
                      <img
                        className="overview-frame desktop"
                        alt="Simplified Banking"
                        src={CccVirtualCardBanner}
                      />
                    </picture>

                    <div className="columns-box">
                      <div className="row">
                        <div className="col-md-6 order-0">
                          <h3 className="size40">Virtual Cards</h3>
                          <p className="size20">
                            Effortlessly assign Virtual Cards to team members
                            for business purchases, all while maintaining utmost
                            security
                          </p>
                        </div>

                        <div className="col-md-12 order-1 action ele-order py-5 py-lg-0">
                          <Link
                            className="button btn-white oca fw-bold"
                            to="/form"
                          >
                            Apply for Corporate Credit Card
                          </Link>
                        </div>

                        <div className="col-md-4 text-center order-2">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={CccVirtualCardMobileBannerLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={CccVirtualCardMobileBanner}
                            ></source>
                            <img
                              className="overview-frame mobile"
                              alt="Simplified Banking"
                              src={CccVirtualCardMobileBanner}
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Virtual Card Assign to Employee Text + Banner */}
            <section
              id="payment-schedule-new"
              className="payment-schedule-new collection-page nobg"
            >
              <div className="container-md">
                <div className="row align-items-center hover-out">
                  <div className="col-md-6">
                    <div className=" payment-image">
                      <div className="image-wrap">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={CccVirtualCardEmpBannerLazy}
                          ></source>
                          <source
                            type="image/png"
                            srcSet={CccVirtualCardEmpBanner}
                          ></source>
                          <img
                            className="desktop hover-img"
                            alt="Send Payment Link"
                            src={CccVirtualCardEmpBanner}
                          />
                        </picture>
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={CccVirtualCardEmpMobileBannerLazy}
                          ></source>
                          <source
                            type="image/png"
                            srcSet={CccVirtualCardEmpMobileBanner}
                          ></source>
                          <img
                            className="mobile hover-img"
                            alt="Send Payment Link"
                            src={CccVirtualCardEmpMobileBanner}
                          />
                        </picture>
                      </div>
                    </div>
                  </div>

                  <div className="virtual-text col-md-5 py-5 pe-lg-5">
                    <div className="payment-text">
                      <h2 className="size40">
                        Create & assign virtual cards to your employees on the
                        fly.
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="monitor-spending" className="receipt-banner-box">
              <div className="container-md">
                <div className="grey-pattern-full">
                  {" "}
                  {/*hover-box */}
                  <div className="row content-box">
                    <div className="col-md-6">
                      <div className="vendor-payments-text">
                        <h3 className="size40 pe-lg-5 pt-lg-5">
                          Keep Track of Receipts across your Company
                        </h3>
                        <p className="size20  py-3 py-lg-0">
                          Efficiently track and reconcile business expenses with
                          our invoice tracker
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={CccReceiptsBannerLazy}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={CccReceiptsBanner}
                        ></source>
                        <img
                          className="desktop hover-img"
                          alt="Schedule to send reminders"
                          src={CccReceiptsBanner}
                        />
                      </picture>
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={CccReceiptsBannerLazy}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={CccReceiptsBanner}
                        ></source>
                        <img
                          className="mobile hover-img"
                          alt="Schedule to send reminders"
                          src={CccReceiptsBanner}
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>

        <section className="section3">
          <div className="d-flex justify-content-center">
            <div className="col-lg-4 text-center py-5 py-lg-5">
              <h2>Integrate with ERP software</h2>
              <p className="size20 pt-2">
                Seamlessly add Tijoree to your existing software stack
              </p>
            </div>
          </div>

          <div className="integration-box row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    src={CccSapBanner}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    src={CccIntuitQuickBanner}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    src={CccZohoBanner}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    src={CccTallyBanner}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq-section" className="ccc-faq">
          <div className="container-md">
            <div className="d-flex justify-content-center">
              <div className="col-lg-4 text-center">
                <h1>FAQ</h1>
                {/* <h5 className="mt-3 opacity-50 fst-italic fw-light">
                  Frequently Asked Questions
                </h5> */}
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-12">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="faq-tab-1"
                    role="tabpanel"
                    aria-labelledby="faq-tab1"
                  >
                    <div className="accordion" id="currentAccount">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="accordion-item accordion-ccc">
                            <h4 className="accordion-header" id="current_faq2">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_2"
                                aria-expanded="false"
                                aria-controls="current_2"
                                data-bs-parent="#currentAccount"
                              >
                                What are the criteria for earning cash back
                                rewards, and are there any limitations?
                              </button>
                            </h4>
                            <div
                              id="current_2"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq2"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  Customers earn a flat cashback on every
                                  transaction and there is no limit to cashback
                                  earnings
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item accordion-ccc">
                            <h4 className="accordion-header" id="current_faq22">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_22"
                                aria-expanded="false"
                                aria-controls="current_22"
                                data-bs-parent="#currentAccount"
                              >
                                Is there a limit to the number of virtual cards
                                that can be generated, and are there any fees
                                associated with their creation?
                              </button>
                            </h4>
                            <div
                              id="current_22"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq22"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  There is no limit to virtual card creation and
                                  it’s free of cost.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item accordion-ccc">
                            <h4 className="accordion-header" id="current_faq3">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_3"
                                aria-expanded="false"
                                aria-controls="current_3"
                                data-bs-parent="#currentAccount"
                              >
                                Will my vendors bear the transaction fees?
                              </button>
                            </h4>
                            <div
                              id="current_3"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq3"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  You have the flexibility to decide what
                                  portion of the transaction fees is borne by
                                  the vendor for every transaction
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item accordion-ccc">
                            <h4 className="accordion-header" id="current_faq4">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_4"
                                aria-expanded="false"
                                aria-controls="current_4"
                                data-bs-parent="#currentAccount"
                              >
                                When are vendor payments settled?
                              </button>
                            </h4>
                            <div
                              id="current_4"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq4"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  Vendor payments will be settled on a T + 1
                                  timeline
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item accordion-ccc">
                            <h4 className="accordion-header" id="current_faq5">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_5"
                                aria-expanded="false"
                                aria-controls="current_5"
                                data-bs-parent="#currentAccount"
                              >
                                Will vendor payments be reflected on my credit
                                card statement?
                              </button>
                            </h4>
                            <div
                              id="current_5"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq5"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  Yes. All transactions made using your physical
                                  or virtual cards on and off our platform will
                                  be reflected on your monthly credit card
                                  statement.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item accordion-ccc">
                            <h4 className="accordion-header" id="current_faq6">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_6"
                                aria-expanded="false"
                                aria-controls="current_6"
                                data-bs-parent="#currentAccount"
                              >
                                What are vendor payments?
                              </button>
                            </h4>
                            <div
                              id="current_6"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq6"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  {" "}
                                  With Tijoree, you can make payments to your
                                  vendor’s bank account directly using your
                                  credit card
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cta-bg-curve" class="cta-bg-curve text-center">
          <div className="container-md">
            <h4>Join Tijoree</h4>
            <Link className="button btn-purple" to={"/form"}>
              Get Started
            </Link>
          </div>
        </section>

        {/* <div className="available-mobile-ccc hover-box">
          <div className="row justify-content-between">
            <div className="col-md-5 text mobile-order-1 text-center text-md-start px-lg-5">
              <h5 className="fw-light text-body fs-5">Corporate Card App</h5>
              <h3 className="download-item">
                Allows you to manage virtual cards, track transactions & upload
                receipts
              </h3>
              <div className="device-list">
                <ul>
                  <li>
                    <a
                      href="https://apps.apple.com/in/app/tijoree/id1600055538"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="hover-img" src={apple} alt="Apple" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=money.tijoree"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="hover-img"
                        src={playstore}
                        alt="Playstore"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 frame mobile-order-2 text-center text-md-start justify-content-center">
              <div class="mx-auto d-block">
                <picture>
                  <source
                    type="image/webp"
                    srcSet={CccCreateCardPhoneLazy}
                  ></source>
                  <source type="image/png" srcSet={CccCreateCardPhone}></source>
                  <img
                    class="hover-img desktop"
                    alt="Available OnMobile"
                    src={CccCreateCardPhone}
                  />
                </picture>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={CccCreateCardPhoneLazy}
                    style={{ background: "18c" }}
                  ></source>
                  <source type="image/png" srcSet={CccCreateCardPhone}></source>
                  <img
                    class="hover-img mobile"
                    alt="Available OnMobile"
                    src={CccCreateCardPhone}
                  />
                </picture>
              </div>
            </div>

            <div className="col-md-6 frame mobile-order-2">
              <picture>
                <source type="image/webp" srcSet={CccCreateCardPhoneLazy}></source>
                <source type="image/png" srcSet={CccCreateCardPhone}></source>
                <img className="hover-img desktop" alt="Available OnMobile" src={CccCreateCardPhone} />
              </picture>
              
              <picture>
                <source type="image/webp" srcSet={CccCreateCardPhoneLazy}></source>
                <source type="image/png" srcSet={CccCreateCardPhone}></source>
                <img className="hover-img mobile" alt="Available OnMobile" src={CccCreateCardPhone} />
              </picture>
            </div>
          </div>
        </div> */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default CorporateCreditCard;
