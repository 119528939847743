import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import EmailIcon from "../assets/img/ic_round-email.svg";
import React from "react";
import TijoreeFooterLogo from "../assets/img/logo-white.svg";
import payableIcon from "../assets/img/payable-icon.svg";
import { NavHashLink } from "react-router-hash-link";
import receivableIcon from "../assets/img/receivable-icon.svg";

// Images imported from assets

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const ScrollUp = () => {
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el, num) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = num;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return (
    <footer>
      <div className="container-md">
        <div className="row">
          <div className="col-md-12  col-lg-12 home-footer">
            <Link to={"/"}>Home</Link>
            <div className="footer-divider logo">
              <div className="logo">
                <img src={TijoreeFooterLogo} alt="tijoree" />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <h4>Products</h4>
            <ul>
              <li className="d-flex align-items-center">
                <img src={payableIcon} className="me-2" alt="arrow" />
                <Link className="text-white fs-6 fw-bold">Payables</Link>
              </li>
              <li>
                <button
                  className="ccbtn"
                  onClick={() => {
                    navigate("/", {
                      state: { id: "business-cc" },
                    });
                  }}
                >
                  Corporate Cards
                </button>
              </li>

              <li>
                <NavHashLink
                  smooth
                  to="/#utilityPayments"
                  scroll={(el) => scrollWithOffset(el, -60)}
                >
                  <span className="arrow"></span>
                  Utility Payments
                </NavHashLink>
              </li>

              <li>
                <NavHashLink
                  smooth
                  to="https://tijoree-live-public.s3.ap-south-1.amazonaws.com/Website/legal.pdf"
                >
                  <span className="arrow"></span>
                  Vendor Payments
                </NavHashLink>
              </li>

              {/* <li>
                <Link to={'/products/payment-collection'} onClick={ScrollUp}>Payment Collection Reminders</Link>
              </li>
              <li>
                <Link to={'/products/pos'} onClick={ScrollUp}>POS & Payment Gateway</Link>
              </li>

              <li>
                <a href='https://loan.tijoree.money/' target='_blank' rel="noreferrer">Apply for a Loan</a>
              </li> */}
            </ul>
          </div>
          {/* <div className="col-md-2">
            <ul className="mt-5">
              <li className="d-flex align-items-center">
                <img src={receivableIcon} className="me-2" alt="arrow" /> 
                <Link className="text-white fs-6 fw-bold">Receivables</Link>
              </li>
              <li>
                <Link onClick={ScrollUp}>Virtual Accounts</Link>
              </li>
              <li>
                <Link onClick={ScrollUp}>Payment Links</Link>
              </li>
              <li>
                <Link to={'/products/payment-collection'} onClick={ScrollUp}>Payment Collection Reminders</Link>
              </li>
            </ul>
          </div> */}

              {/*
          <div className="col-md-2">
            <h4>Resources</h4>
            <ul>
               <li>
                <Link to={"/faq"} onClick={ScrollUp}>
                  FAQs
                </Link>
              </li> 
              <li>
                <Link to={'/faq'} onClick={ScrollUp}>Client Case Studies</Link>
              </li>
              <li>
                <Link to={"/blog"} onClick={ScrollUp}>
                  Blog
                </Link>
              </li>
            </ul>
          </div>
                */}

          <div className="col-md-2">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to={"/about"} onClick={ScrollUp}>
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/tijoree-money/jobs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Careers
                </a>
              </li>
              <li>
                <Link to={"/form"} onClick={ScrollUp}>
                  Contact Us
                </Link>
              </li>

              <li>
                <a
                  href="https://tijoree-live-public.s3.ap-south-1.amazonaws.com/Website/e-form_MGT-7.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="arrow"></span>
                  Corporate Information
                </a>
              </li>
              {/* <li>
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={'/terms-conditions'}>Terms & Conditions</Link>
              </li> */}
            </ul>
          </div>

          <div className="col-md-2">
            <h4>Follow Us</h4>
            <ul>
              <li className="linkedin">
                <a
                  href="https://www.linkedin.com/company/tijoree-money/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </li>
              <li className="twitter">
                <a
                  href="https://twitter.com/tijoreemoney?s=21&t=VptfLfGqMLEGgAb0E-u9zw"
                  target="_blank"
                  rel="noreferrer"
                >
                  X (formerly Twitter)
                </a>
              </li>
              <li className="youtube">
                <a
                  href="https://www.youtube.com/@tijoreemoney"
                  target="_blank"
                  rel="noreferrer"
                >
                  Youtube
                </a>
              </li>
            </ul>
          </div>


          {/* <div className="col-md-4">
            <h4>Additional Details</h4>
            <p>Registered Office: B-601, Kanakia Wall Street, Chakala, Andheri Kurla Road, Andheri E, Mumbai 400093</p>
            <p>CIN: U72900MH2020PTC351876</p>
            <br />

            <span style={{ color: "#fff", fontSize: 15 }}>In case of queries or grievances, please contact: </span>
            <ul style={{ color: "#fff", fontSize: 15, }}>
              <li>Mr. Nagaraju Mogullapalli</li>
              <li>Email ID: <a href="mailto:grievances@tijoree.money">grievances@tijoree.money</a></li>
              <li>Mob. No.: <a href="tel:+919152020329">+91 9152020329</a></li>
            </ul>
          </div> */}


          <div className="col-md-4">
            <h4>Additional Details</h4>
            <div className="address-group">
              <p>Registered Office: B-601, Kanakia Wall Street, Chakala, Andheri Kurla Road, Andheri E, Mumbai 400093</p>
            </div>

            <div className="cni-group">
              <p>CIN: U72900MH2020PTC351876</p>
            </div>

            <div className="contact-group">
              <p>In case of queries or grievances, please contact:</p>
              <ul>
                <li>Mr. Nagaraju Mogullapalli</li>
                <li>Email ID: <a href="mailto:grievances@tijoree.money">grievances@tijoree.money</a></li>
                <li>Mob. No.: <a href="tel:+919152020329">+91 9152020329</a></li>
              </ul>
            </div>
          </div>


        </div>
        <br />
        <br />
        <div className="col-md-5 col-lg-3 lg-d-flex justify-content-center">
          <a className="lg-d-flex" href="mailto:support@tijoree.money">
            <span style={{ color: "#fff", opacity: 0.7, fontSize: 15 }}>
              Contact Us
            </span>
            &nbsp;&nbsp;&nbsp;
            <img src={EmailIcon} alt="Email" style={{ paddingBottom: "3px" }} />
            &nbsp;
            <span style={{ color: "#fff", fontSize: 15 }}>
              support@tijoree.money
            </span>
          </a>
        </div>
        <div className="footer-divider"></div>
        <div className="row">
          <div className="col-md-11 col-lg-11">
            <div className="inner-items">
              <ul>
                <li>
                  <Link to={"/terms-conditions"}>Terms & Conditions</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Grievances & Redressal</Link>
                </li>
                <li>
                  <Link to={"/refund-cancellation"}>Refund & Cancellation</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Partner Bank Policies</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-5 col-lg-5">
            <a className="d-flex justify-content-lg-end text-light" href='mailto:support@tijoree.money'>
              <img src={EmailIcon} alt="Email" />&nbsp;support@tijoree.money
            </a>
          </div> */}
          <div className="col-md-1 col-lg-1">
            <div className="locations">
              <ul className="d-flex justify-content-md-end">
                <li className="india">India</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-md-center">
            <div className="copyright">
              &copy; Tijoree 2024 Copyrights and All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
