class Environment {
    static PROD_URL = 'https://api.tijoree.money/blog/category/all';
    static DEV_URL = 'https://apidev.tijoree.money/blog/category/all';

    static getUrl() {
        const env = process.env.NODE_ENV; 

        if (env === 'prod') {
            return this.PROD_URL;
        } else {
            return this.DEV_URL;
        }
    }
}

export default Environment;
